
import { VenueModel } from "@/api/generated";
import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { DataTableHeader } from "vuetify";
import nameof from "@/utility/nameof";
import api from "@/api";
import SnackbarModule from "@/store/snackbarModule";
import UserModule from "@/store/userModule";
import userRoleConstant from "@/Constants/userRoleConstant";

const snackbarModule = getModule(SnackbarModule);
const userModule = getModule(UserModule);

@Component
export default class VenueTable extends Vue {
  private loading = false;
  private showArchived = false;
  private items: Array<VenueModel> = [];
  private headers: Array<DataTableHeader<VenueModel>> = [
    { text: "", value: "actions", sortable: false },
    { text: "Name", value: nameof<VenueModel>("name") },
    { text: "Country", value: nameof<VenueModel>("country") },
    { text: "Address Line One", value: nameof<VenueModel>("addressLineOne") },
    { text: "Address Line Two", value: nameof<VenueModel>("addressLineTwo") },
    { text: "Postcode", value: nameof<VenueModel>("postcode") },
  ];

  private get adminPermissions() {
    return (
      userModule.userRole == userRoleConstant.systemAdministrator ||
      userModule.userRole == userRoleConstant.operationsManager
    );
  }

  private get portfolioManagerPermissions() {
    return userModule.userRole == userRoleConstant.porfolioManager;
  }

  private get customerAdminPermissions() {
    return userModule.userRole == userRoleConstant.customerAdmin;
  }

  private async created() {
    await this.getVenues();
  }

  private async getVenues() {
    try {
      this.loading = true;
      if (this.adminPermissions) {
        const response = await api.VenueService.apiVenueGet();
        this.items = response.data;
      }
      if (this.portfolioManagerPermissions || this.customerAdminPermissions) {
        const response =
          await api.VenueService.apiVenueOrganisationvenuesOrganisationIdGet(
            userModule.organisationId
          );
        this.items = response.data;
      }
    } catch {
      snackbarModule.setSnackbarMessage("Failed to retrieve Venues");
    } finally {
      this.loading = false;
    }
  }

  get filteredVenues(): Array<VenueModel> {
    if (this.items.length > 0) {
      return this.items.filter((x) => x.archived == this.showArchived);
    }
    return [];
  }
}
