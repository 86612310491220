
import api from "@/api";
import { VenueCreateModel } from "@/api/generated";
import Validation from "@/mixins/validation";
import SnackbarModule from "@/store/snackbarModule";
import { VForm } from "@/types/vForm";
import { Component, PropSync, Ref, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

const snackbarModule = getModule(SnackbarModule);

@Component
export default class VenueCreateDialog extends Validation {
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;
  @Ref() private readonly form!: VForm;

  private loading = false;
  private model: VenueCreateModel = {
    name: "",
    country: "",
    addressLineOne: "",
    addressLineTwo: "",
    postcode: "",
  };

  @Watch("syncedDialog")
  private onDialogClose() {
    if (!this.syncedDialog) {
      return;
    }

    this.form?.reset();
  }

  private async onVenueCreate() {
    if (!this.form.validate()) {
      return;
    }

    try {
      this.loading = true;
      await api.VenueService.apiVenuePost(this.model);
      snackbarModule.setSnackbarMessage("Venue created");
      this.$emit("created");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to create venue");
    } finally {
      this.loading = false;
      this.syncedDialog = false;
    }
  }
}
