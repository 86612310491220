
import { Component, Prop, PropSync, Ref, Watch } from "vue-property-decorator";
import SnackbarModule from "@/store/snackbarModule";
import { getModule } from "vuex-module-decorators";
import Validation from "@/mixins/validation";
import { VForm } from "@/types/vForm";
import { VenueUpdateModel } from "@/api/generated";
import api from "@/api";

const snackbarModule = getModule(SnackbarModule);

@Component
export default class VenueUpdateDialog extends Validation {
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;
  @Prop(String) venueId!: string;
  @Ref() private readonly form!: VForm;

  private loading = false;
  private model: VenueUpdateModel = {
    name: "",
    country: "",
    addressLineOne: "",
    addressLineTwo: "",
    postcode: "",
  };

  @Watch("syncedDialog")
  private async onDialogClose() {
    if (!this.syncedDialog) {
      this.form?.reset();
    }

    const response = await api.VenueService.apiVenueVenueIdGet(this.venueId);
    this.model = response.data;
  }

  private async created() {
    const response = await api.VenueService.apiVenueVenueIdGet(this.venueId);
    this.model = response.data;
  }

  private async onVenueUpdate() {
    if (!this.form?.validate()) {
      return;
    }

    try {
      this.loading = true;
      await api.VenueService.apiVenueVenueIdPut(this.venueId, this.model);
      snackbarModule.setSnackbarMessage("Venue updated");
      this.$emit("updated");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to update venue");
    } finally {
      this.loading = false;
      this.syncedDialog = false;
    }
  }
}
